import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Box, TextField, Button, Typography, Container, Paper, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { WEBSITE_URL } from 'src/utils/constant';

const AddProjectSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(1, 'Title must be at least 1 character')
    .max(255, 'Title must not exceed 255 characters'),
  description: Yup.string().required('Description is required').min(1, 'Description must be at least 1 character'),
  cover: Yup.mixed()
    .required('Cover image is required')
    .test('fileType', 'Unsupported file format', (value) => {
      if (value instanceof File) {
        return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type);
      }
      return true;
    }),
  images: Yup.array()
    .of(
      Yup.mixed()
        .required('Image is required')
        .test('fileType', 'Unsupported file format', (value) => {
          if (value) {
            return ['image/jpeg', 'image/png', 'image/webp'].includes(value.type);
          }
          return false;
        })
    )
    .min(1, 'At least one image is required')
    .nullable(),
});

const ProjectForm = ({ initialData, onSubmit, formTitle, isLoading, formID }) => {
  const [formData, setFormData] = useState(
    initialData || {
      title: '',
      description: '',
      images: [],
      cover: null,
    }
  );
  const [errors, setErrors] = useState({});

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevData) => ({ ...prevData, images: files }));
    validateField('images', files);
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, cover: file }));
    validateField('cover', file);
  };

  const validateField = useCallback(async (name, value) => {
    try {
      await AddProjectSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AddProjectSchema.validate(formData, { abortEarly: false });
      onSubmit(formData);
    } catch (validationErrors) {
      const newErrors = {};
      if (validationErrors.inner) {
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
      }
      console.log(newErrors);
      setErrors(newErrors);
    }
  };

  const urlToFile = useCallback(async (imageUrl, fileName) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error('Error converting URL to File:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const initializeForm = async () => {
      if (initialData) {
        const updatedFormData = { ...initialData };
        for (const key of Object.keys(initialData)) {
          if (key === 'images' && initialData.images && Array.isArray(initialData.images)) {
            const imageFiles = await Promise.all(
              initialData.images.map(async (imageUrl) => {
                if (typeof imageUrl === 'string') {
                  const fileName = imageUrl.split('/').pop();
                  return await urlToFile(WEBSITE_URL + imageUrl, fileName);
                }
                return null;
              })
            );
            updatedFormData.images = imageFiles.filter((file) => file !== null);
          }
          if (key === 'cover' && initialData.cover && typeof initialData.cover === 'string') {
            const fileName = initialData.cover.split('/').pop();
            const coverFile = await urlToFile(WEBSITE_URL + initialData.cover, fileName);
            if (coverFile) {
              updatedFormData.cover = coverFile;
            }
          }
          await validateField(key, updatedFormData[key]);
        }
        setFormData(updatedFormData);
      }
    };

    initializeForm();
  }, []);

  const handleDeleteImage = (index) => {
    setFormData((prevData) => {
      const updatedImages = [...prevData.images];
      updatedImages.splice(index, 1);
      return { ...prevData, images: updatedImages };
    });
    validateField('images', formData.images);
  };

  const handleDeleteCover = () => {
    setFormData((prevData) => ({ ...prevData, cover: null }));
    validateField('cover', null);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          {formTitle}
        </Typography>
        <Box component="form" id={formID} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            type="file"
            id="cover-upload"
            variant="outlined"
            accept="image/*"
            sx={{ mb: 2 }}
            inputProps={{
              style: { display: 'none' },
            }}
            onChange={handleCoverChange}
            hidden
          />
          <label htmlFor="cover-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              startIcon={<Iconify icon="ic:round-add-a-photo" />}
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                mb: 2,
                padding: '16px',
                textAlign: 'center',
                '&:hover': {
                  border: '2px dashed #1976d2',
                },
              }}
            >
              <Typography variant="body1">Click to upload cover image</Typography>
            </Button>
          </label>
          {formData.cover && (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: 'inline-block', mr: 1, mb: 1, position: 'relative' }}>
                <img
                  src={
                    formData.cover instanceof File ? URL.createObjectURL(formData.cover) : WEBSITE_URL + formData.cover
                  }
                  alt="Cover Preview"
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <IconButton
                  onClick={() => handleDeleteCover()}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: 'red',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                >
                  <Iconify icon="eva:trash-2-outline" />
                </IconButton>
              </Box>
            </Box>
          )}
          {errors.cover && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors.cover}
            </Typography>
          )}
          <TextField
            type="file"
            id="images-upload"
            variant="outlined"
            accept="image/*"
            sx={{ mb: 2 }}
            inputProps={{
              multiple: true,
              style: { display: 'none' },
            }}
            onChange={handleImageChange}
            hidden
            multiple
          />
          <label htmlFor="images-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              startIcon={<Iconify icon="ic:round-add-a-photo" />}
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                mb: 2,
                padding: '16px',
                textAlign: 'center',
                '&:hover': {
                  border: '2px dashed #1976d2',
                },
              }}
            >
              <Typography variant="body1">Click to upload multiple images</Typography>
            </Button>
          </label>

          {formData.images && formData.images.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {formData.images.map((image, index) => (
                <Box key={index} sx={{ display: 'inline-block', mr: 1, mb: 1, position: 'relative' }}>
                  <img
                    src={image instanceof File ? URL.createObjectURL(image) : WEBSITE_URL + image}
                    alt={`Preview ${index + 1}`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'red',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                  >
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
          {errors.images && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors.images}
            </Typography>
          )}

          <TextField
            fullWidth
            name="title"
            label="Project Title"
            value={formData.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="description"
            label="Project Description"
            value={formData.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            form={formID}
          >
            {initialData ? 'Update Project' : 'Create Project'}
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProjectForm;
